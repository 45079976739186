body {
  background: #e8eaf6 ;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  overflow: hidden;
}

.rd3t-node {
  stroke-width: 2px
}

.rd3t-link {
  stroke: #9ba4f7;
  stroke-width: 3px;
}

.link__className {
  stroke: #9ba4f7;
  stroke-width: 2px
}

g.rd3t-g {
  margin: 20px 30px 0px 20px;
}

path.rd3t-link {
  stroke: #323a80;
  stroke-width: 4px
}

.node__root > circle {
  fill:  #39439c;
  stroke: #323a80;
  stroke-width: 4px
}

.node__branch > circle {
  fill: #6875F5;
}

.node__branch.rd3t-node {
  fill: #6875F5;
  stroke-width: 4px;
  stroke: #39439c;
}

.node__branch.rd3t-leaf-node {
  stroke-width: 1px;
  stroke: #39439c;
}